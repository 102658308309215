<template>
  <div class="content-box">
    <van-tabs @click="onTabClick" color="#3d7a2c">
      <van-tab title="任务项">
        <div class="dis-flex-row ju-sb ft-sz-16 pd-15" style="background-color: #f7f7f7;">
          <div class="status-item" :class="{'status-active':status==''}" @click="changeStatus('')">全部</div>
          <div class="status-item" :class="{'status-active':status=='1'}" @click="changeStatus('1')">运行中</div>
          <div class="status-item" :class="{'status-active':status=='2'}" @click="changeStatus('2')">已停止</div>
          <div class="status-item" :class="{'status-active':status=='3'}" @click="changeStatus('3')">配置中</div>
        </div>
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getTaskData" :style="{'height':listH+'px','background-color': '#f7f7f7','overflow':'auto'}">
          <div v-for="item in taskList" :key="item.id" class="dis-flex-row ju-sb pd-10 mg-b-15" style="background-color:#ffffff;">
            <div class="flx-1 dis-flex-com ju-c mg-r-20">
              <div class="ft-sz-16 pd-y-10">
                <span class="qiusofont qiusoweixin1"></span>
                <span style="padding:0 15px;">{{item.name}}</span>
                <span class="qiusofont qiusoa-bianji1" @click="editTask(item)"></span>
              </div>
              <div class="dis-flex-row ju-sb">
                <span>
                  今日条数： {{item.number}} 条     
                </span>
                <span>
                  最近更新： {{item.time}}
                </span>
              </div>
            </div>
            <div class="dis-flex-com al-c ju-c ft-sz-16" style="color:green;">
              <span class="qiusofont qiusobofang ft-sz-34"></span>
              <span>进行中</span>
            </div>
          </div>
        </van-list>
        <div style="padding: 0.1rem 15%; width:100%;position: fixed;bottom: 0.1rem;box-sizing: border-box;">
          <van-button round block size="normal" type="info" @click="addTask">创建新任务项</van-button>
        </div>
      </van-tab>
      <van-tab title="最新数据" >

      </van-tab>
    </van-tabs>
    <van-dialog v-model="showTaskEdit" title="修改名称" show-cancel-button @cancel="showTaskEdit = false;" :before-close="taskBeforeClose">
      <van-form ref="taskForm" validate-first class="pd-10 mg-b-10"  @submit="saveTask">
        <van-field v-model="task.name" label="名称" :rules="[{ required: true, message: '请填写名称' }]" />
        <van-field v-model="task.remarkName" label="备注名称" :rules="[{ required: true, message: '请填写备注名称' }]"/>
      </van-form>
    </van-dialog>
  </div>
</template>
<script>
import http from '../../lib/api.js'
import util from '../../lib/util.js'
export default {
  data() {
    return {
      status: '',
      taskList: [],
      loading: false,
      finished: false,
      task:{
        id:'',
        name:'',
        remarkName:''
      },
      showTaskEdit:false,
      cHeight: document.documentElement.clientHeight
    }
  },
  computed: {
		listH: function () {
      console.log(this.cHeight)
			return this.cHeight - 550;
		}
	},
  created() {

  },
  mounted() {
    window.onresize = () => {
			this.cHeight = document.documentElement.clientHeight;
		}
  },
  methods: {
    onTabClick(name, title) {

    },
    changeStatus(val) {
      this.status = val;
      this.getTaskData();
    },
    addTask(){

    },
    editTask(item){
      this.showTaskEdit = true;
      this.task.name = item.name;
      this.task.remarkName = '';
      this.task.id = item.id;
    },
    taskBeforeClose(action, done){
      if (action === 'confirm') {
        this.$refs.taskForm.validate().then(valid =>{
          console.log(valid)
        }).catch(e=>{
          console.log(e)
          done(false) 
        });
        // done();
      } else {
        done();
      }
    },
    saveTask(){
      console.log(111)
      return false;
    },
    getTaskData() {
      this.taskList = [{
        id:1,
        name:'国家粮食局河北局--采集',
        number:23,
        time:'23:00'
      },{
        id:2,
        name:'中国政府网公众号',
        number:222,
        time:'12:00'
      },{
        id:3,
        name:'发改委公众号',
        number:222,
        time:'12:00'
      },{
        id:4,
        name:'中国政府网公众号',
        number:222,
        time:'12:00'
      },{
        id:5,
        name:'发改委公众号',
        number:222,
        time:'12:00'
      },{
        id:6,
        name:'中国政府网公众号',
        number:222,
        time:'12:00'
      },{
        id:7,
        name:'发改委公众号',
        number:222,
        time:'12:00'
      },{
        id:8,
        name:'中国政府网公众号',
        number:222,
        time:'12:00'
      },{
        id:9,
        name:'发改委公众号',
        number:222,
        time:'12:00'
      },{
        id:10,
        name:'中国政府网公众号',
        number:222,
        time:'12:00'
      },{
        id:11,
        name:'发改委公众号',
        number:222,
        time:'12:00'
      },{
        id:12,
        name:'中国政府网公众号',
        number:222,
        time:'12:00'
      },{
        id:13,
        name:'发改委公众号',
        number:222,
        time:'12:00'
      },{
        id:14,
        name:'中国政府网公众号',
        number:222,
        time:'12:00'
      },{
        id:15,
        name:'发改委公众号',
        number:222,
        time:'12:00'
      },{
        id:16,
        name:'中国政府网公众号',
        number:222,
        time:'12:00'
      },{
        id:21,
        name:'发改委公众号',
        number:222,
        time:'12:00'
      }]
       // 加载状态结束
      // this.loading = false;

      // 数据全部加载完成
      if (this.taskList.length >= 40) {
        this.finished = true;
      }
    }
  }

}
</script>
<style scoped>
.content-box {
  color: #333333;
}
.status-active {
  color: #3d7a2c;
}

</style>